import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import sharedStyle from "assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"
import { navigateTo } from "gatsby-link"
import { Link } from "gatsby";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...this.state
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="Intro">
            <GridContainer>
              <GridItem md={12} sm={12}>
                <div className={classes.container}>
                  <h3>
                  <b><u><Link to={"/covid-19-policy"} className={classes.listItem}> Covid-19 Info </Link>  </u> </b>                 
                  <br />
                    Get in touch today to arrange a free consultation.                   
<br />
<br />

                    Send us an email, give us a call or use the contact form
                    below. Alternatively come visit us at our Kent based
                    workshop for a complimentary coffee and a chat about all
                    things Land Rover!
                    <br />
                  </h3>
                  <form
                      name="contact"
                      method="post"
                      action="/message-sent"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                      <GridContainer>
                        <input type="hidden" name="form-name" value="contact" />
                        <p hidden>
                          <label>
                            Don’t fill this out:
                            <input
                              name="bot-field"
                              onChange={this.handleChange}
                            />
                          </label>
                        </p>
                        <GridItem xs={12} sm={12} md={6}>
                        <label>Name
                          <CustomInput
                            type="text"
                            formControlProps={{
                              required: true,
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "Name",
                              onChange: this.handleChange,
                            }}
                          />
                        </label>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                        <label>Email
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              required: true,
                            }}
                            inputProps={{
                              type: "email",
                              name: "Email address",
                              onChange: this.handleChange,
                            }}
                          />
                        </label>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                        <label>Message
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              required: true,
                            }}
                            inputProps={{
                              multiline: true,
                              rows: 6,
                              name: "Your message",
                              onChange: this.handleChange,
                            }}
                          />
                        </label>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.textCenter}>
                          <Button color="primary" type="submit">
                            Send
                          </Button>
                        </div>
                        </GridItem>
                      </GridContainer>
                    </form>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(sharedStyle)(ContactForm)
